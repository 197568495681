import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
//const iAUrl='iad'
//const path = window.location.href
//if(path.includes(iAUrl)){
  localStorage.setItem("iadUser", true);
  document.body.classList.add('iaStyle');
  document.querySelector("title").innerHTML = "InventiAir - Dashboard";
  document.querySelector("link[rel='shortcut icon']").href = "/favicon_ia.png";
//}

// if(localStorage.getItem("iadUser")){
//   document.querySelector("title").innerHTML = "InventiAir - Dashboard";
//   document.querySelector("link[rel='shortcut icon']").href = "/favicon_ia.png";
//   document.body.classList.add('iaStyle');
// }

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)



// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
          <Route path="/login" component={Login} exact={true} />
          {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" component={Page404} />              
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Dashboard" render={props => <TheLayout {...props}/>} /> 
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
